import {LoaderFunctionArgs, type MetaFunction} from '@remix-run/node'
import {Enderman} from "#app/components/enderman/enderman.tsx";
import {prisma} from "#app/utils/db.server.ts";
import {useLoaderData} from "@remix-run/react";
import tableStyles from './minecraft-table.module.css'
import {pingServer} from "#app/utils/minecraft.server.ts";

export const meta: MetaFunction = () => [{title: 'Drodyx'}]

export async function loader({}: LoaderFunctionArgs) {
    const servers = await prisma.server.findMany();
    const pings = await Promise.all(servers.map(server => pingServer({ host: server.host, port: server.port || undefined})));

    return pings.map(s => ({
        host: s.port ? `${s.host}:${s.port}` : s.host,
        online: s.offline ? 'Offline' : `${s.playerCount}/${s.maxPlayers}`,
        version: s.offline ? '' : s.version,
        players: s.offline ? [] : s.players?.map(p => p.name) || []
    }))
}

export default function Index() {
    const data = useLoaderData<typeof loader>()

    return (
        <main className="font-poppins grid h-full place-items-center">
            <div className="place-items-center px-4">
                <div className={tableStyles.host}>
                    <table className="border-collapse text-[#555555]">
                        <thead>
                        <tr>
                            <th className="p-4 border border-gray-600">Сервер</th>
                            <th className="p-4 border border-gray-600">Онлайн</th>
                            <th className="p-4 border border-gray-600">Версия</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            data.map(d => (
                                <tr key={d.host}>
                                    <td className="p-4 border border-gray-600">{d.host}</td>
                                    <td className="p-4 border border-gray-600">
                                        {d.online}
                                        <br/>
                                        {d.players.map(p => (<><span key={p}>{p}</span> <br/></>))}
                                    </td>
                                    <td className="p-4 border border-gray-600">{d.version}</td>
                                </tr>))
                        }
                        </tbody>
                    </table>
                </div>


                <div
                    className="mt-2 xl:mt-16 flex justify-center">
                    <Enderman></Enderman>
                </div>
            </div>
        </main>
    )
}
