import * as React from "react";
import styles from "./enderman.module.css";


export const Enderman = () => {
  return <div className={styles.host}>
    <div className={styles.enderman}>
      <div className={styles.head}>
        <div className={styles.front}>
          <span className={styles.leftEye}></span>
          <span className={styles.rightEye}></span>
        </div>
        <div className={styles.bottom}></div>
        <div className={styles.left}></div>
        <div className={styles.right}></div>
      </div>
      <div className={styles.mouth}></div>
      <div className={styles.endermanBody}></div>
      <div className={styles.leftArm}></div>
      <div className={styles.rightArm}></div>
      <div className={styles.leftLeg}></div>
      <div className={styles.rightLeg}></div>

      <ul className={styles.enderDust}>
        <li className={styles.particle}></li>
        <li className={styles.particle}></li>
        <li className={styles.particle}></li>
        <li className={styles.particle}></li>
        <li className={styles.particle}></li>
        <li className={styles.particle}></li>
        <li className={styles.particle}></li>
        <li className={styles.particle}></li>
      </ul>

    </div>
  </div>
}